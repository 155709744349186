<template>
  <div
    class="main-menu menu-fixed menu-accordion menu-shadow"
    :class="[
      {
        expanded:
          !isVerticalMenuCollapsed ||
          (isVerticalMenuCollapsed && isMouseHovered),
      },
      skin === 'semi-dark' ? 'menu-dark' : 'menu-light',
    ]"
    @mouseenter="updateMouseHovered(true)"
    @mouseleave="updateMouseHovered(false)"
  >
    <!-- main menu header-->
    <div class="navbar-header expanded" style="height: 100px">
      <slot
        name="header"
        :toggleVerticalMenuActive="toggleVerticalMenuActive"
        :toggleCollapsed="toggleCollapsed"
        :collapseTogglerIcon="collapseTogglerIcon"
      >
        <ul class="nav navbar-nav flex-row">
          <!-- Logo & Text -->
          <li class="nav-item mr-auto d-flex align-items-center">
            <b-link class="navbar-brand w-100 mx-auto" to="/">
              <span
                class="brand w-100 ml-auto d-flex align-items-center justify-content-center"
                style="min-width: 32px; height: 30px"
              >
                <feather-icon
                  v-if="isVerticalMenuCollapsed && !isMouseHovered"
                  icon="MenuIcon"
                  size="24"
                />
                <div v-else-if="skin != 'dark'">
                  <!-- <OptiMultiDark /> -->
                  <OptiMulti
                    v-if="userData.moduleid == 2"
                    class="ml-1"
                    height="38px"
                  />
                  <OptiPro
                    v-if="userData.moduleid == 1"
                    class="ml-1"
                    height="38px"
                  />
                </div>
                <div v-else>
                  <OptiMultiDark
                    v-if="userData.moduleid == 2"
                    class="ml-1"
                    height="38px"
                  />

                  <OptiProDark
                    v-if="userData.moduleid == 1"
                    class="ml-1"
                    height="38px"
                  />
                </div>
              </span>
            </b-link>
          </li>
          <!-- Toggler Button -->
          <li class="nav-item nav-toggle">
            <b-link class="nav-link modern-nav-toggle">
              <feather-icon
                icon="XIcon"
                size="20"
                class="d-block d-xl-none"
                @click="toggleVerticalMenuActive"
              />
              <feather-icon
                :icon="collapseTogglerIconFeather"
                size="20"
                class="d-none d-xl-block collapse-toggle-icon"
                @click="toggleCollapsed"
              />
            </b-link>
          </li>
        </ul>
      </slot>
    </div>
    <!-- / main menu header-->

    <!-- Shadow -->
    <div :class="{ 'd-block': shallShadowBottom }" class="shadow-bottom" />

    <!-- main menu content-->
    <vue-perfect-scrollbar
      :settings="perfectScrollbarSettings"
      class="main-menu-content scroll-area"
      tagname="div"
      @ps-scroll-y="
        (evt) => {
          shallShadowBottom = evt.srcElement.scrollTop > 0;
        }
      "
    >
      <vertical-nav-menu-items
        :items="navMenuItems"
        class="navigation navigation-main"
      />

      <div></div>
    </vue-perfect-scrollbar>
    <!-- </main menu content> -->
  </div>
</template>

<script>
import navMenuItems from "@/navigation/vertical";
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import { BLink, BImg } from "bootstrap-vue";
import { provide, computed, ref } from "@vue/composition-api";
import useAppConfig from "@core/app-config/useAppConfig";
import { $themeConfig } from "@themeConfig";
import VerticalNavMenuItems from "./components/vertical-nav-menu-items/VerticalNavMenuItems.vue";
import useVerticalNavMenu from "./useVerticalNavMenu";
import Logo from "@/assets/images/logo/logo.png";
import vSelect from "vue-select";
import store from "@/store";
import FeatherIcon from "@/@core/components/feather-icon/FeatherIcon.vue";
import OptiMulti from "@/layouts/components/OptiMulti.vue";
import OptiPro from "@/layouts/components/OptiPro.vue";
import OptiProDark from "@/layouts/components/OptiProDark.vue";
import OptiMultiDark from "@/layouts/components/OptiMultiDark.vue";
import { getUserData } from "@/auth/utils";
export default {
  components: {
    BLink,
    VuePerfectScrollbar,
    VerticalNavMenuItems,
    OptiMulti,
    OptiPro,
    OptiMultiDark,
    OptiProDark,
    // vSelect,
  },
  FeatherIcon,
  props: {
    isVerticalMenuActive: {
      type: Boolean,
      required: true,
    },
    toggleVerticalMenuActive: {
      type: Function,
      required: true,
    },
  },

  setup(props) {
    const {
      isMouseHovered,
      isVerticalMenuCollapsed,
      collapseTogglerIcon,
      toggleCollapsed,
      updateMouseHovered,
    } = useVerticalNavMenu(props);
    const userData = getUserData();
    const { skin } = useAppConfig();

    // Shadow bottom is UI specific and can be removed by user => It's not in `useVerticalNavMenu`
    const shallShadowBottom = ref(false);

    provide("isMouseHovered", isMouseHovered);
    // const plantData = store.state.plantStoreModule.plantData;

    const perfectScrollbarSettings = {
      maxScrollbarLength: 60,
      wheelPropagation: false,
    };

    const collapseTogglerIconFeather = computed(() =>
      collapseTogglerIcon.value === "unpinned" ? "CircleIcon" : "DiscIcon"
    );
    const plantData = computed(() =>
      store.state.plantsModule.plantData.map((p) => {
        return {
          val: p.id,
          label: p.plantname,
        };
      })
    );

    if (plantData.value.length >= 0) {
      store.dispatch("plantsModule/fetchPlants");
      store.commit(
        "app/UPDATE_PLANT",
        store.state.app.selectedPlantId == 0
          ? plantData.value[0]
          : store.state.app.selectedPlantId
      );
    }

    const selectedPlant = computed(() => {
      return plantData.value.filter(
        (p) => p.val == store.state.app.selectedPlantId
      );
    });
    // App Name
    const { appName, appLogoImage } = $themeConfig.app;

    const updateSelectedPlant = ({ val }) => {
      localStorage.setItem("plantid", val);
      store.commit("app/UPDATE_PLANT", val);
      window.location.reload();
    };
    store.commit("app/UPDATE_PLANT", localStorage.getItem("plantid"));

    return {
      navMenuItems,
      perfectScrollbarSettings,
      isVerticalMenuCollapsed,
      collapseTogglerIcon,
      toggleCollapsed,
      isMouseHovered,
      updateMouseHovered,
      collapseTogglerIconFeather,
      updateSelectedPlant,
      plantData,
      selectedPlant,
      // Shadow Bottom
      shallShadowBottom,

      // Skin
      skin,

      // App Name
      appName,
      appLogoImage,
      userData,
    };
  },
};
</script>

<style lang="scss">
@import "~@core/scss/base/core/menu/menu-types/vertical-menu.scss";
</style>
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>

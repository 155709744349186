<template>
  <li
    v-if="canViewVerticalNavMenuLink(item)"
    class="nav-item"
    :class="{
      active: isActive,
      disabled: item.disabled,
    }"
  >
    <b-link
      :id="'menu-' + item.title"
      v-bind="linkProps"
      class="d-flex align-items-center"
    >
      <div>
        <feather-icon
          v-if="!item.src"
          :icon="item.icon || 'CircleIcon'"
          style="height: 24px; width: 24px"
        />
      </div>
      <component
        :is="item.src"
        v-if="item.src"
        class="text-truncate"
        style="height: 24px; width: 24px"
      />
      <span :id="item.title + '-text'" class="menu-title text-truncate">{{
        t(item.title)
      }}</span>
      <b-badge
        v-if="item.tag"
        pill
        :variant="item.tagVariant || 'primary'"
        class="mr-1 ml-auto"
      >
        {{ item.tag }}
      </b-badge>
    </b-link>

    <b-tooltip
      :target="'menu-' + item.title"
      triggers="hover"
      placement="right-start"
      :delay="{ show: 500, hide: 20 }"
      boundary="document"
    >
      <span v-if="isTruncated"> {{ t(item.title) }}</span>
    </b-tooltip>
  </li>
</template>

<script>
import { useUtils as useAclUtils } from "@core/libs/acl";
import { BLink, BBadge, BTooltip } from "bootstrap-vue";
import { useUtils as useI18nUtils } from "@core/libs/i18n";
import useVerticalNavMenuLink from "./useVerticalNavMenuLink";
import mixinVerticalNavMenuLink from "./mixinVerticalNavMenuLink";
import { ref, computed, onMounted } from "@vue/composition-api";

export default {
  components: {
    BLink,
    BBadge,
    BTooltip,
  },
  mixins: [mixinVerticalNavMenuLink],
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const { isActive, linkProps, updateIsActive } = useVerticalNavMenuLink(
      props.item
    );
    const params = props.params ? props.params : null;
    const { t } = useI18nUtils();
    const { canViewVerticalNavMenuLink } = useAclUtils();
    const isTruncated = ref(false);

    onMounted(() => {
      const textElem = document.getElementById(props.item.title + "-text");
      const menuItemElem = document.getElementById("menu-" + props.item.title);
      const handler = ({ target }) => {
        isTruncated.value = textElem.offsetWidth < textElem.scrollWidth;
        menuItemElem.removeEventListener("mouseenter", handler);
      };
      if (menuItemElem != null)
        menuItemElem.addEventListener("mouseenter", handler);
    });

    return {
      isActive,
      linkProps,
      updateIsActive,
      params,
      // ACL
      canViewVerticalNavMenuLink,

      // i18n
      t,
      //
      isTruncated,
    };
  },
};
</script>
<style lang="scss">
.show {
  .nav-item {
    margin-left: 22px !important;
  }
}
</style>
